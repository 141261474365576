// подсветка посещенных резюме
@visitedLinkColor: #804080;

.obj-highlight {
  &:visited {
    color: @visitedLinkColor;
  }

  &.visited {
    color: @visitedLinkColor;
  }
}
@import "../../../library/scripts/common-static/less/prefixer";;

.tariffs-activate-formatter {
    margin: 0 auto; 
    width: 400px; 
}

.tariffs-activate {
  .wrapper {
    float: left;
  }

  input[type=radio]:checked + .tariff {
    .linear-gradient-top(#fefefe, #fefefe, 0%, #dcf4ff, 50%);
    box-shadow: 0px 0px 5px #dcf4ff;
  }

  input[type=radio]:disabled + .tariff {
    color: slategray;
    cursor: not-allowed;
  }

  .tariff {
    cursor: pointer;
    .linear-gradient-top(#fefefe, #fefefe, 0%, #f6f6f6, 50%);
    width: 120px;
    border: 1px solid #cacaca;
    display: block;
    text-align: center;
    margin: 5px;
  }

  .hidden {
    display: none;
  }


}

.ui-dialog {
  z-index: 10001;
}
@import "../slim-layout/vars";
@import "../modern-link";

.slim-header {
    .logo-row {
        .content {
            padding-left: 315px;


            .sites-list{
                width: 150px;
                float: left;
            }
            .rabota-stat{
                float: left;
                height: 100%;
                padding: 2px 0px;
                margin-left: 50px;

                p{
                    margin: 0px;

                    a{
                        .modern-link(lighten(@color-text,30%),@color-text);
                    }

                    .fresh{
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
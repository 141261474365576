@import "../../../library/scripts/common-static/less/prefixer";
@import "../../../library/scripts/common-static/less/material-shadow";
@import "_variables";



.rabota-stats-header {

  color: white;
  font-size: 16px;
  margin: 10px 0;

  .stats-block {
    background: @stats-block-background-color;
    height: 130px;

    .title {
      color: white;
      font-weight: bold;
      text-align: center;
      margin-bottom: 10px;
    }

    .description {
      width: 80%;
      margin: 0 auto;

      a {
        color: white;
      }
    }

    .badge {
      background: white;
      display: inline-block;
      border-radius: 5px;
      color: black;
      min-width: 25px;
      text-align: center;
      padding-right: 3px;
    }

    .actions {
      width: 80%;
      margin: 10px auto;

      &__margin {
        margin-top: 25px;
      }

      //.btn {
      //  margin-top: 5px;
      //  padding: 5px 0;
      //  color: black;
      //  text-align: center;
      //  text-decoration: none;
      //  font-size: 15px;
      //  width: 100%;
      //  display: inline-block;
      //  background: white;
      //  .z-depth(1);
      //  .transition(0.1s);
      //
      //  &:hover {
      //    .z-depth(2);
      //  }
      //
      //  &:active {
      //    .z-depth(0);
      //  }
      //}


      .btn {

        border: 1px solid #a7b8c0;
        border-radius: 3px;
        line-height: 17px;
        padding: 5px 0 5px 5px;
        background: white;

        display: inline-block;
        width: 100%;
        color: black;
        text-align: center;
        text-decoration: none;
        font-size: 15px;
        cursor: pointer;
        .z-depth(1);
        .transition(0.1s);

        &:hover {
          .z-depth(2);
        }

        &:active {
          .z-depth(0);
        }

        &__blue {
          background: @search-panel-background-color;
          color: @search-panel-text-color;
          border-color: @search-panel-background-color;
        }
      }
    }
  }
}
.rules-site {

  .header {

  }

  #tabs {

    ul {
      padding: 0;
      border-bottom: 1px solid #ddd;

      li {
        list-style-type: none;
        display: inline-block;
        position: relative;
        margin-bottom: -1px;
        white-space: normal;
        width:226px;

        .tab {
          padding: 10px 13px;
          text-align: center;
          display: inline-block;
          text-decoration: none;
          width: 200px;
          word-wrap:normal;
          white-space: normal;
          border: 1px solid transparent;
          border-radius: 4px 4px 0 0;

          &.active {
            border: 1px solid #ddd;
            border-bottom-color: transparent;
            background-color: #fff;
          }
        }
      }
    }
  }

  .content {

    .section-info {

    }

    .hidden {
      display: none;
    }

    .section-controls {
      float: right;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .overflow-text {
      height: 400px;
      overflow-y: scroll;
    }

  }

}

@import "../slim-layout/vars.less";

.slim-header {
    .sites-list {
        padding: 0px 0px 2px 0px;
        margin: 0px 0px 0px 0px;
        list-style-type: none;
        height: 100%;
        vertical-align: top;
        overflow: hidden;

        li {
            height: 100%;
            font-size: 13px;
            display: inline-block;

            a {
                text-decoration: none;
                vertical-align: middle;
                display: inline-block;
                height: 100%;
                color: @color-text;
                padding: 0px 3px;
                margin: 0px 2px;
                border-bottom: 2px solid transparent;

                .transition(all .2s);

                span {
                    display: inline-block;
                    .transition(all .2s);
                    color: @color-text;
                    text-transform: lowercase;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 12px;
                    padding: 24px 0px 10px 0px;

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }

                &:hover {
                    border-color: @color-link-hover;
                }
            }

            &.img-title{

                img{
                    padding-top: 17px;
                    height: 25px;
                    width: auto;
                    vertical-align: middle;
                }
            }
        }
    }
}
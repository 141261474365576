@import "../../../library/scripts/common-static/less/prefixer";


.blue-card {
    margin: 10px 0;
    padding: 10px 15px 10px;
    background-color: #dcf4ff;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 0px 5px #dcf4ff;
}

.blue-button {
    background: #198fd4;
    .gradient(#198fd4, #198fd4, #34aee3);
    width: 180px;
    border-radius: 6px;
    font-size: 13.3px;
    font-weight: bold;
    color: #FFF !important;
    border: none;
    border-bottom: 2px solid #0c547e;
    vertical-align: top;
    cursor: pointer;
    display: inline-block;
    margin: 5px;
    padding: 5px 20px;
    text-align: center;
    text-decoration: none;
    white-space: normal;
    box-sizing: border-box;

    &:hover {
        background: #329edf;
        .gradient(#329edf, #4cbaec, #329edf);
    }

    &:disabled{
        cursor: default;
        background: #5E5C5E;
        background-image: none;
        color: #B3B3B6 !important;
        .linear-gradient-top(#5E5C5E, #B3B3B6, 0%, #5E5C5E, 25%, #666666, 100%);
    }

    &.disabled{
        cursor: default;
        background: #5E5C5E;
        background-image: none;
        color: #B3B3B6 !important;
        .linear-gradient-top(#5E5C5E, #B3B3B6, 0%, #5E5C5E, 25%, #666666, 100%);
    }

}

.profile-buttons-line .blue-button {
        width: 160px;
        height: 60px;
        line-height: 20px;      
}

.addition-padding {
    padding: 10px 0;
}

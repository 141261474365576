@import "../../../library/scripts/common-static/less/prefixer";
@import "../../../library/scripts/common-static/less/material-shadow";
@import "../../../library/vendor/vse42/default-skin/less/bootstrap-master/mixins/clearfix";
@import "_variables";

.title-wrapper {

    .title {
        display: inline-block;
    }

    .right-section {
        display: inline-block;
        position: absolute;
        top: 15px;
        right: 7px;
    }
}

.rabota-search-panel {
    //border-radius: 3px;
    //overflow: hidden;

    &.extended {
        background-color: #f9fdff;
    }

    .blue-background {
        background-color: @search-panel-background-color;
    }

    .light-blue-background {
        background: #D9EEFB;
    }

    #grid_1_wider {
        width: 100px;
    }

    .input {
        display: inline-block;
        border: 1px solid @search-button-border-color;
        border-radius: 3px;
        //margin: 15px 0;
        line-height: 17px;
        padding: 5px 0 5px 5px;
        background: white;
        box-sizing: border-box;
        .transition(0.1s);

        &:focus {
            .z-depth(1);
        }

        &--full {
            width: 100%;
        }

        &--half {
            width: 100% / 2 - 2% * 2;
        }

        &--thrid {
            width: 100% / 3 - 2% * 3;
        }

    }

    .select {
        margin-top: 5px;
    }

    .btn {
        display: inline-block;
        width: 100%;
        color: black;
        text-align: center;
        text-decoration: none;

        cursor: pointer;
        margin: 0;

        border: none;
        .z-depth(1);
        .transition(0.1s);

        &:hover {
            .z-depth(3);
        }

        &:active {
            .z-depth(0);
        }

        &__blue {
            background: @search-panel-background-color;
            color: @search-panel-text-color;
            border-color: @search-panel-background-color;
        }
    }

    .submit-btn{
        padding: 23px;
        vertical-align: middle;
        font-size: 19px;
    }

    .grid_7 {
        margin-left: 20px;
        width: 530px;
    }

    .options {
        .addition {
            float: right;
            margin-top: -25px;
        }
    }

    .tabs {
        //width: 100%;
    }

    .tab {
        float: left;

        &_label {
            font-size: 16px;
            display: block;
            color: @search-panel-background-color;
            padding: 10px;
            margin-left: -1px;
            position: relative;
            left: 1px;
            cursor: pointer;
            text-decoration: none;
        }

        &__active {
            background: @search-panel-background-color;
            color: white;
            border: none;
            //border-bottom-left-radius: 3px;
            //border-bottom-right-radius: 3px;
        }

        label {
            display: block;
            color: @search-panel-background-color;
            padding: 10px;
            margin-left: -1px;
            position: relative;
            left: 1px;
            cursor: pointer;
        }

        [type=radio] {
            display: none;
        }
    }

    [type=radio]:checked ~ label {
        background: @search-panel-background-color;
        color: white;
        border: none;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .line {

        padding: 15px 0;
        .clearfix();

        span.section-title {

            font-weight: bold;
            padding-left: 10px;
        }
    }

    label {
        white-space: nowrap;
    }

    &.addition-filter-params {
        display: inline-block;
        width: 50% - 2%;
    }
}
